<template>
  <div>
    <div>
      <breadcrumb name="Category Services" title="Services" subtitle="Copy" @onTitleClicked="goBack"/>
    </div>
    <div class="body">
      <vs-col v-if="!loading" vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="12">
        <services
          :item="serviceDetails"
          @addService="addService"
          @cancel="goBack"
          @update="confirmSubmitData"
          :key="serviceCounter"
        />
      </vs-col>
    </div>
  </div>

</template>

<script>
  import Services from "../../../../../components/form/model-services/services";
  import Breadcrumb from "../../../../../components/general/breadcrumb";
  import ChooseModel from "../../../../../components/custom/choose-model";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    name: 'copy-service',
    props: {
    },
    data() {
      return {
        serviceCounter: 0,
        loading: true,
        editorConfig: {
          placeholder: 'Details'
        },
        editor: ClassicEditor,
        modelID: 0,
        categoryID: 0,
        serviceID: 0,
        serviceDetails: {
          km: '',
          price: '',
          details: [{
            ar: '',
            en: ''
          }]
        }
      }
    },
    methods: {
      goBack()
      {
        this.$router.push('/categories/show/' + this.categoryID);
      },
      getModel()
      {
        this.categoryID = this.$route.params.id;

        this.$httpCars
          .get(`categories/${this.categoryID}`)
          .then(r => {
            this.item = r.data.data;
            this.modelID = this.item.model.id;
            this.$vs.loading.close();
            this.loading = false;
            this.loadServicesData();
          })
          .catch(() => {
            //console.log({getCategoryById_error: e});
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Error',
              text: 'Error is occurred!\nCouldn\'t Save Data!',
              color: 'danger'
            });
          });

      },
      addService() {
        this.item.services.push({
          km: 0,
          price: 0,
          details: [{
            en: "",
            ar: "",
          }],
        });
      },
      confirmSubmitData(serviceDetails) {
        console.log({emittedData: serviceDetails});
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.submitData(serviceDetails),
          color: 'primary',
          title: 'Save Services',
          text: 'You are going to save this data'
        });
      }
      ,
      submitData(serviceDetails) {
        let mappedDetails = {"en": [], "ar": []};
        for (let i = 0; i < serviceDetails.details.length; i++) {
          mappedDetails.ar.push(serviceDetails.details[i].ar);
          mappedDetails.en.push(serviceDetails.details[i].en);
        }
        let payload = {
          km: serviceDetails.km,
          price: serviceDetails.price,
          model_id: this.modelID,
          details: mappedDetails,
          category_id: this.categoryID

        };

        this.$httpCars.post('/models/addservice', payload)
          .then(r => {
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: 'Saved',
              text: 'Data is Saved Successfully',
              color: 'primary'
            });
            this.goBack();
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: 'Error is occurred!\nCouldn\'t Save Data!',
              color: 'danger'
            });
          });
      },
      loadServicesData () {
        this.serviceID = this.$route.params.serviceId;
        if (this.item.services.length !== 0) {

          for (let i = 0; i <this.item.services.length ; i++) {
            if(this.serviceID === ""+ this.item.services[i].id){
              this.serviceDetails.price = this.item.services[i].price;
              this.serviceDetails.km = this.item.services[i].km;
              this.item.services[i].details = JSON.parse(this.item.services[i].details);
              if(typeof this.item.services[i].details.en != "undefined")
              {
                if(this.item.services[i].details.en.length > 0)
                {
                  this.serviceDetails.details = [];
                  for (let j = 0; j <this.item.services[i].details.en.length; j++) {
                    this.serviceDetails.details.push({en:this.item.services[i].details.en[j], ar:this.item.services[i].details.ar[j]});
                  }
                }
              }

            }
          }
        }
      }
    },
    components: {ChooseModel, Breadcrumb, Services},
    mounted() {
      this.categoryID = this.$route.params.id;
      this.loading = false;
      this.getModel();
    }
  }
</script>

<style scoped lang="scss">
  @import "src/views/basic-styles/mixins";

  .flex {
    justify-content: flex-end;
    padding-right: 75px;
  }

  .input {
    width: 100%;
    margin-top: 10px;
  }
  .optionBtns {
    width: 186%;
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    .save {
      position: relative;
      left: 25px;
    }
  }
  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: right;
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding-right: 50px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .body{
    overflow: hidden;
  }

</style>
